@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0B1620;
}

.bm-burger-bars {
  top: 0px !important;
}

.modal-overlay {
  position: fixed;
  z-index: 444444;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.8);
}

.bg-move-left {
  background-position-x: calc(50% - 44px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.shadow {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
}

.bg-embrace1 {
  background-image: linear-gradient(0deg, #000 0%, rgba(255, 255, 255, 0) 100%),
    url(../public/images/background/embrace.png);
}

@media screen and (max-width: 600px) {
  #STC-sticky-child {
    top: 12% !important;
  }

  #value {
    top: 26% !important;
  }
}

.bg-embrace2 {
  background-image: linear-gradient(0deg, #000 0%, rgba(255, 255, 255, 0) 100%),
    url(../public/images/background/embrace2.png);
}

.bg-embrace3 {
  background-image: linear-gradient(0deg, #000 0%, rgba(255, 255, 255, 0) 100%),
    url(../public/images/background/embrace3.png);
}

.arrow-button .arrow {
  width: 15px;
  transition: all 0.3s ease;
}

.arrow-button:hover .arrow {
  width: 40px;
}

.arrow-button .arrow {
  border: 1px solid #fff;

  background: #fff;
}

.arrow-button .arrow .head,
.arrow-button .arrow .head:after {
  border: 1px solid #fff;
  border-radius: 2px;
  width: 12px;
  position: absolute;
  right: -5px;
  top: 2.2px;
  transform: rotate(-45deg);
}

.arrow-button .arrow .head:after {
  content: "";
  border-color: #ffffff;
  right: -6px;
  top: -6px;
  transform: rotate(90deg);
}

.arrow-button .arrowblack {
  width: 15px;
  transition: all 0.3s ease;
}

.arrow-button:hover .arrowblack {
  width: 40px;
  border: 1px solid #fff;

  background: #fff;
}

.arrow-button .arrowblack {
  border: 1px solid #000;

  background: #000;
}

.arrow-button .arrowblack .head {
  border: 1px solid #000;
  border-radius: 2px;
  width: 12px;
  position: absolute;
  right: -5px;
  top: 2.2px;
  transform: rotate(-45deg);
}

.arrow-button .arrowblack .head:after {
  border: 1px solid #000;
  border-radius: 2px;
  width: 12px;
  position: absolute;
  right: -5px;
  top: 2.2px;
  transform: rotate(-45deg);
}

.arrow-button .arrowblack .head:after {
  content: "";
  border-color: #000;
  right: -6px;
  top: -6px;
  transform: rotate(90deg);
}

.arrow-button:hover .arrowblack,
.arrow-button:hover .arrowblack .head {
  background: #fff; /* Change background to white */
  border-color: #fff; /* Change border to white */
}

.arrow-button:hover .arrowblack .head:after {
  border-color: #fff; /* Change border of the :after pseudo-element to white */
  background: #fff; /* Change background of the :after pseudo-element to white */
}

#mainImage {
  transition: opacity 1s;
  opacity: 1;
}

#slide-container {
  position: relative;
}

.fade-enter,
.fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fade-enter,
.fade-exit-done {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}

.geniune {
  background: linear-gradient(to right, #cefbe3 50%, #2ebd85 50%);
}
@media screen and (max-width: 900px) {
  .geniune {
    background: none;
  }
}

.max-screen-1440 {
  @apply max-w-[1440px] mx-auto;
}
.footer-title {
  @apply text-light-green font-roboto-bold text-[16px] md:text-[10px] sm:text-[14px] sm:mb-[9px];
}

.footer-sub-title {
  @apply text-white font-thin text-[16px] md:text-[10px] sm:text-12 sm:mb-[9px];
}

.footer-privacy-title {
  @apply text-white font-normal text-[12px] md:text-[10px] sm:text-[9px];
}

.integrated .slick-track {
  height: 300px;
}

.map-container {
  position: relative;
  width: 100% !important;
  height: 656px;
}

/* .mapboxgl-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.mapboxgl-marker {
  position: absolute;
} */

/* .mapboxgl-control-container {
  display: none;
}

.mapboxgl-canvas {
  width: 100% !important;
  height: 800px !important;
}

.mapboxgl-canvas-container {
  width: 100% !important;
  height: 800px !important;
} */

@media screen and (max-width: 600px) {
  .mapboxgl-canvas {
    width: 100% !important;
    height: 456px !important;
  }
  .mapboxgl-canvas-container {
    width: 100% !important;
    height: 456px !important;
  }

  .map-container {
    height: 450px;
  }
}

.team-hover {
  background: #0b1620d9;
  opacity: 0.85;
  width: 100%;
  padding-bottom: 20px;
  height: fit-content;
  border-radius: 10px;
}

.home-slick-slider .slick-prev {
  right: 45px;
  top: 28px;
  left: auto;
  z-index: 11;
}

.bm-menu-wrap {
  width: 100% !important;
  top: 0;
  background-color: white;
}

.react-burger-menu-btn {
  z-index: 0px;
}

#react-burger-menu-btn {
  display: none;
}

@media screen and (max-width: 600px) {
  .home-slick-slider .slick-prev {
    top: 18px;
    right: 31px;
  }
}

.home-slick-slider .slick-next {
  right: 0px;
  top: 28px;
  left: auto;
}

@media screen and (max-width: 600px) {
  .home-slick-slider .slick-next {
    top: 18px;
  }
}

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .home-slick-slider .slick-prev {
    top: 3px;
  }
}

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .home-slick-slider .slick-next {
    top: 3px;
  }
}

@font-face {
  font-family: "roboto-light";
  src: local("roboto-light"),
    url("../src/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-bold";
  src: local("roboto-bold"),
    url("../src/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-med";
  src: local("roboto-med"),
    url("../src/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-reg";
  src: local("roboto-reg"),
    url("../src/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

.card:hover .box {
  @apply w-[100%] h-[inherit] m-[0px];
}
.animated:hover {
  transform: translateY(-15px);
  transition: 300ms;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 29px;
  width: 29px;
  border: 2px solid #040b11;
  background-color: white;
  border-radius: 5px;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .checkmark {
    height: 18px;
    width: 18px;
    border: 1px solid #040b11;
    border-radius: 3px;
    margin-top: 1px;
  }
}

/* On mouse-over, add a grey background color */

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2ebd85;
  border: none;
  width: 29px;
  height: 29px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 11px;
  top: 6px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .container input:checked ~ .checkmark {
    height: 18px;
    width: 18px;
  }
  .container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
  }
}

.integrated .slick-prev {
  right: 77px;
  left: inherit;
  bottom: -18px;
  top: inherit;
  z-index: 1;
}

.integrated .slick-next {
  right: 0px;
  left: inherit;
  bottom: -18px;
  top: inherit;
}

.integrated .slick-next:before {
  content: "";
}

.integrated .slick-prev:before {
  content: "";
}
